import React from "react";
import engagementRingImageSrc from "../public/engagement_rings.png";

import style from "./Custom.css";
export default class Custom extends React.Component {
  render() {
    return (
      <div className="Custom">
        <div>
          <h4>Jewelry Design</h4>
          <p>
            Firestone and Parson staff and in-house jewelers have comprehensive
            knowledge of traditional and modern jewelry manufacturing and
            design. We are passionate about making beautiful jewelry of high
            quality and high integrity in design and execution. We work with
            stones from our stock, or stones supplied by customers. We also
            offer expertise in sourcing fine stones and semi-precious material,
            where we make effective use of longstanding trade relationships.
          </p>
        </div>
        <div>
          <h4>Engagement Rings</h4>
          <p>
            Firestone and Parson staff particularly enjoy offering our services
            to engagement ring clients. We have extensive experience
            manufacturing rings in modern and traditional styles, and offer our
            stock of diamonds and fine emeralds, rubies, and sapphires,
            including the best examples of old and modern shapes and cuts. We
            are available to have background conversations with first-time
            customers, and offer our view of the best way to achieve a ring of
            appropriate quality and value.
          </p>
        </div>

        <img alt="4 engagement rings on a table" src={engagementRingImageSrc} style={{ width: "90%", margin: "0 auto", display: "block" }} />
      </div>
    );
  }
}
