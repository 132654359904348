import * as types from "../constants/actions";
import update from "react/lib/update";

const initialState = {
  loading    :    true,
  loaded     :    false
}

export default function data(state=initialState, action) {
  switch (action.type) {
    case types.REQUEST_DATA_SUCCESS:
      return update(state, {
        data: { $set: action.data },
        loading: { $set: false },
        loaded:  { $set: true }
      })
    default:
      break;
  }
}

