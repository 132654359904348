import React, { Component } from "react";
import { Link } from "react-router";
import { connect } from "react-redux";

import firebaseRoot from "../utils/firebaseRoot";

require("../css/normalize.css");
require("../css/skeleton.css");
require("../css/styles.scss");

import {
  requestDataPending,
  requestDataSuccess,
  requestDataError,
} from "../actions/data.js";

requestDataPending();

function connectFirebase(dispatch) {
  firebaseRoot.on("value", (snapshot) => {
    let data = snapshot.val();
    if (data) {
      dispatch(requestDataSuccess(data));
    } else {
      dispatch(requestDataError());
    }
  });
}

const GOLDEN = 1.618;

class TopLeft extends Component {
  componentDidMount() {
    this.draw();
  }

  draw() {
    let canvas = this.refs.canvas.getDOMNode();
    let ctx = canvas.getContext("2d");

    let path = new Path2D();
    let size = this.props.size;
    let diff = parseInt(size / 30);

    let side1 = parseInt(size / 3);
    path.moveTo(0.5, size + 0.5);
    path.lineTo(0.5, side1 + 0.5);
    path.lineTo(side1 + 0.5, side1 + 0.5);
    path.lineTo(side1 + 0.5, 0.5);
    path.lineTo(size + 0.5, 0.5);
    path.lineTo(size + diff, +0.5 + diff);

    let diff2 = diff;
    let side2 = parseInt(size / 3) - diff2;
    path.moveTo(diff2 + 0.5, size + 0.5);
    path.lineTo(diff2 + 0.5, side2 + 0.5);
    path.lineTo(side2 + 0.5, side2 + 0.5);
    path.lineTo(side2 + 0.5, diff2 + 0.5);
    path.lineTo(size + 0.5, diff2 + 0.5);
    ctx.stroke(path);

    let diff3 = diff * 2;
    let side3 = parseInt(size / 3) - diff3;
    path.moveTo(diff3 + 0.5, size + 0.5);
    path.lineTo(diff3 + 0.5, side3 + 0.5);
    path.lineTo(side3 + 0.5, side3 + 0.5);
    path.lineTo(side3 + 0.5, diff3 + 0.5);
    path.lineTo(size + 0.5, diff3 + 0.5);
    ctx.stroke(path);

    let diff4 = diff * 3;
    let side4 = parseInt(size / 3) - diff4;
    path.moveTo(diff4 + 0.5, size + 0.5);
    path.lineTo(diff4 + 0.5, side4 + 0.5);
    path.lineTo(side4 + 0.5, side4 + 0.5);
    path.lineTo(side4 + 0.5, diff4 + 0.5);
    path.lineTo(size + 0.5, diff4 + 0.5);
    ctx.stroke(path);
  }

  render() {
    return (
      <canvas ref="canvas" height={this.props.size} width={this.props.size} />
    );
  }
}

class TopRight extends Component {
  componentDidMount() {
    this.draw();
  }

  draw() {
    let canvas = this.refs.canvas.getDOMNode();
    let ctx = canvas.getContext("2d");

    let path = new Path2D();
    let size = this.props.size;
    let diff = parseInt(size / 30);

    let side1 = parseInt(size / 3);
    path.moveTo(0.5, size + 0.5);
    path.lineTo(0.5, side1 + 0.5);
    path.lineTo(side1 + 0.5, side1 + 0.5);
    path.lineTo(side1 + 0.5, 0.5);
    path.lineTo(size + 0.5, 0.5);

    let diff2 = diff;
    let side2 = parseInt(size / 3) - diff2;
    path.moveTo(diff2 + 0.5, size + 0.5);
    path.lineTo(diff2 + 0.5, side2 + 0.5);
    path.lineTo(side2 + 0.5, side2 + 0.5);
    path.lineTo(side2 + 0.5, diff2 + 0.5);
    path.lineTo(size + 0.5, diff2 + 0.5);

    let diff3 = diff * 2;
    let side3 = parseInt(size / 3) - diff3;
    path.moveTo(diff3 + 0.5, size + 0.5);
    path.lineTo(diff3 + 0.5, side3 + 0.5);
    path.lineTo(side3 + 0.5, side3 + 0.5);
    path.lineTo(side3 + 0.5, diff3 + 0.5);
    path.lineTo(size + 0.5, diff3 + 0.5);

    let diff4 = diff * 3;
    let side4 = parseInt(size / 3) - diff4;
    path.moveTo(diff4 + 0.5, size + 0.5);
    path.lineTo(diff4 + 0.5, side4 + 0.5);
    path.lineTo(side4 + 0.5, side4 + 0.5);
    path.lineTo(side4 + 0.5, diff4 + 0.5);
    path.lineTo(size + 0.5, diff4 + 0.5);

    ctx.translate(size, 0);
    ctx.scale(-1, 1);
    ctx.stroke(path);
  }

  render() {
    return (
      <canvas
        ref="canvas"
        style={{ position: "absolute", right: 0 }}
        height={this.props.size}
        width={this.props.size}
      />
    );
  }
}

class BottomLeft extends Component {
  componentDidMount() {
    this.draw();
  }

  draw() {
    let canvas = this.refs.canvas.getDOMNode();
    let ctx = canvas.getContext("2d");

    let path = new Path2D();
    let size = this.props.size;
    let diff = parseInt(size / 30);

    let side1 = parseInt(size / 3);
    path.moveTo(0.5, size + 0.5);
    path.lineTo(0.5, side1 + 0.5);
    path.lineTo(side1 + 0.5, side1 + 0.5);
    path.lineTo(side1 + 0.5, 0.5);
    path.lineTo(size + 0.5, 0.5);

    let diff2 = diff;
    let side2 = parseInt(size / 3) - diff2;
    path.moveTo(diff2 + 0.5, size + 0.5);
    path.lineTo(diff2 + 0.5, side2 + 0.5);
    path.lineTo(side2 + 0.5, side2 + 0.5);
    path.lineTo(side2 + 0.5, diff2 + 0.5);
    path.lineTo(size + 0.5, diff2 + 0.5);

    let diff3 = diff * 2;
    let side3 = parseInt(size / 3) - diff3;
    path.moveTo(diff3 + 0.5, size + 0.5);
    path.lineTo(diff3 + 0.5, side3 + 0.5);
    path.lineTo(side3 + 0.5, side3 + 0.5);
    path.lineTo(side3 + 0.5, diff3 + 0.5);
    path.lineTo(size + 0.5, diff3 + 0.5);

    let diff4 = diff * 3;
    let side4 = parseInt(size / 3) - diff4;
    path.moveTo(diff4 + 0.5, size + 0.5);
    path.lineTo(diff4 + 0.5, side4 + 0.5);
    path.lineTo(side4 + 0.5, side4 + 0.5);
    path.lineTo(side4 + 0.5, diff4 + 0.5);
    path.lineTo(size + 0.5, diff4 + 0.5);

    ctx.translate(0, size);
    ctx.scale(1, -1);
    ctx.stroke(path);
  }

  render() {
    return (
      <canvas
        ref="canvas"
        style={{ position: "absolute", left: 0, bottom: 0 }}
        height={this.props.size}
        width={this.props.size}
      />
    );
  }
}
class BottomRight extends Component {
  componentDidMount() {
    this.draw();
  }

  draw() {
    let canvas = this.refs.canvas.getDOMNode();
    let ctx = canvas.getContext("2d");

    let path = new Path2D();
    let size = this.props.size;
    let diff = parseInt(size / 30);

    let side1 = parseInt(size / 3);
    path.moveTo(0.5, size + 0.5);
    path.lineTo(0.5, side1 + 0.5);
    path.lineTo(side1 + 0.5, side1 + 0.5);
    path.lineTo(side1 + 0.5, 0.5);
    path.lineTo(size + 0.5, 0.5);

    let diff2 = diff;
    let side2 = parseInt(size / 3) - diff2;
    path.moveTo(diff2 + 0.5, size + 0.5);
    path.lineTo(diff2 + 0.5, side2 + 0.5);
    path.lineTo(side2 + 0.5, side2 + 0.5);
    path.lineTo(side2 + 0.5, diff2 + 0.5);
    path.lineTo(size + 0.5, diff2 + 0.5);

    let diff3 = diff * 2;
    let side3 = parseInt(size / 3) - diff3;
    path.moveTo(diff3 + 0.5, size + 0.5);
    path.lineTo(diff3 + 0.5, side3 + 0.5);
    path.lineTo(side3 + 0.5, side3 + 0.5);
    path.lineTo(side3 + 0.5, diff3 + 0.5);
    path.lineTo(size + 0.5, diff3 + 0.5);

    let diff4 = diff * 3;
    let side4 = parseInt(size / 3) - diff4;
    path.moveTo(diff4 + 0.5, size + 0.5);
    path.lineTo(diff4 + 0.5, side4 + 0.5);
    path.lineTo(side4 + 0.5, side4 + 0.5);
    path.lineTo(side4 + 0.5, diff4 + 0.5);
    path.lineTo(size + 0.5, diff4 + 0.5);

    ctx.translate(0, size);
    ctx.scale(1, -1);
    ctx.translate(size, 0);
    ctx.scale(-1, 1);
    ctx.stroke(path);
  }

  render() {
    return (
      <canvas
        ref="canvas"
        style={{ position: "absolute", bottom: 0, right: 0 }}
        height={this.props.size}
        width={this.props.size}
      />
    );
  }
}
import style from "./Main.css";
class Main extends Component {
  componentWillMount() {
    this.props.dispatch(requestDataPending());
    connectFirebase(this.props.dispatch);
    this.state = {};
  }

  handleMouseEnter() {
    this.setState({
      price: true,
    });
  }

  handleMouseLeave() {
    this.setState({
      price: false,
    });
  }

  renderLoading() {
    return <div> ...loading </div>;
  }

  renderMain() {
    let activeStyle = { borderTop: "1px solid black" };
    return (
      <div className={"Main " + style.Main} styles={{ minWidth: "1024px" }}>
        <Link to={`/`} aria-label="A link to Firestone and Parson landing page">
          <div className="logo" />
        </Link>
        <span className={"catalogLink"}>
          <a href="/holidaycatalogue2023.pdf" target="_blank" download>
            Holiday Catalogue 2023
          </a>
        </span>
        <div className="Nav">
          <div className="SubNav">
            <nav>
              <ul>
                <li>
                  <Link activeStyle={activeStyle} to={`/jewelry`}>Jewelry</Link>
                </li>
                <li>
                  <Link activeStyle={activeStyle} to={`/silver`}>Silver</Link>
                </li>
                <li>
                  <Link activeStyle={activeStyle} to={`/gifts`}>Gifts</Link>
                </li>
                <li>
                  <Link activeStyle={activeStyle} to={`/featured`}>
                    Featured
                  </Link>
                </li>
                <li>
                  <Link activeStyle={activeStyle} to={`/custom`}>Custom</Link>
                </li>
                <li>
                  <Link activeStyle={activeStyle} to={`/services`}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link activeStyle={activeStyle} to={`/aboutus`}>
                    About Us
                  </Link>
                </li>
                <li className="priceCodeLi">
                  <div
                    onTouchStart={this.handleMouseEnter.bind(this)}
                    onTouchCancel={this.handleMouseLeave.bind(this)}
                    onMouseEnter={this.handleMouseEnter.bind(this)}
                    onMouseLeave={this.handleMouseLeave.bind(this)}
                    href="#"
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      Price Code
                    </a>
                    {this.state.price && (
                      <div
                        className="priceCode"
                        aria-hidden={!this.state.price}
                      >
                        <div className="colorCode">
                          <div style={{ backgroundColor: "#C077D2" }} />
                          up to $2,500
                        </div>
                        <div className="colorCode">
                          <div style={{ backgroundColor: "#6A87D0" }} />
                          $2,500 to $5,000
                        </div>
                        <div className="colorCode">
                          <div style={{ backgroundColor: "#ECDF11" }} />
                          $5,000 to $10,000
                        </div>
                        <div className="colorCode">
                          <div style={{ backgroundColor: "#E06969" }} />
                          $10,000 to $20,000
                        </div>
                        <div className="colorCode">
                          <div style={{ backgroundColor: "#E89F20" }} />
                          $20,000 to $50,000
                        </div>
                        <div className="colorCode">
                          <div style={{ backgroundColor: "#89F189" }} />
                          $50,000 +
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="contentWrapper">
          <div className="topLeft corner"><TopLeft size={100} /></div>
          <div className="topRight corner"><TopRight size={100} /></div>
          <div className="bottomLeft corner"><BottomLeft size={100} /></div>
          <div className="bottomRight corner"><BottomRight size={100} /></div>
          <main className="Body">{this.props.children}</main>
        </div>
        <div className="footer">
          <span className="email">
            <a href="mailto:info@firestoneandparson.com">
              info@firestoneandparson.com
            </a>
          </span>
          <span className="rights">
            617-266-1858 | 17 Arlington Street, Second Floor, Boston MA 02116 | ©2023
          </span>
        </div>
        <div className="ada">
          <Link to="/aboutus">
            firestoneandparson.com endeavors to be ADA compliant
          </Link>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.loaded) {
      return this.renderMain();
    } else {
      return this.renderLoading();
    }
  }
}

function mapStateToProps(state) {
  return { ...state };
}

export default connect(mapStateToProps)(Main);
