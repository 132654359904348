import React from "react";
import {connect } from "react-redux";
import Domain from "../components/Domain";

class Silver extends React.Component {
  render() {
    return <Domain
      name="Silver"
      { ...this.props }
    />
  }
}

function mapStateToProps(state) {
  let { categories } = state.data;

  let antiqueEnglishSilver = categories["Antique English Silver"];
  let earlyAmericanSilver  = categories["Early American Silver"];
  let modernSilver         = categories["Modern Silver"];

  return {
    categories: {
      "Antique English Silver": antiqueEnglishSilver,
      "Early American Silver": earlyAmericanSilver,
      "Modern Silver": modernSilver
    }
  }
}

export default connect(mapStateToProps)(Silver);

