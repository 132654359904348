import React from "react";
import { Link }  from "react-router";
import CategoryTile from "./CategoryTile";

export default class PrimaryCategory extends React.Component {

  renderSubCategories() {
    let children = [];
    for (var key in this.props.subCategories ) {
      let link =
        ("/"+ this.props.domain  +"/" + this.props.name + "/" + key ).toLowerCase();
      link = window.escape(link);
      children.push(<h3>
        <Link to={ link }>{ key }</Link>
      </h3>);
    }
    return children;
  }
 
  getCategoryElements() {
    let { subCategories } = this.props;
    let tiles = []
    for (var key in subCategories) {
      let link =
        ("/"+ this.props.domain  +"/" + this.props.name + "/" + key ).toLowerCase();
      link = window.escape(link);
      let subCategory = subCategories[key];
      tiles.push(
        <div className={ "flexElement" }>
        <CategoryTile
          key={ key }
          domain = { this.props.name }
          category={ key }
          link={ link }
          { ...subCategory } />
        </div>)
    }
    return tiles;
  }

  getRows() {
    let elements = this.getCategoryElements();
    let chunked = _.chunk(elements, 3);
    return _.map(chunked, (chunk) => {
      return <div className={ "flexRow" }>
        { chunk }
      </div>
    })
  }

  render() {
    return <div className="PrimaryCategory">
      { this.getRows() }
    </div>
  }
}
