import React from "react";
import {connect } from "react-redux";
import TerminalCategory from "../components/TerminalCategory";
import _ from "lodash";

export default function terminalCategoryComponent(primary, secondary) {
  let Component = class TerminalCategoryContainer extends React.Component {
    render () {
       return <TerminalCategory items = { this.props.items } />
    }
  }
  function mapStateToProps(state) {
    let order = state.data.categories[primary].hasSubCategories ?
      state.data.subCategories[primary][secondary].order :
      state.data.categories[primary].order
    let items = order ? order.map( id => state.data.itemsById[id] ) : [];
    items = _.compact(items);
    return {
      items
    }
  }
  return connect(mapStateToProps)(Component);
}

