import * as types from '../constants/actions';

export function requestDataPending() {
  return {
    type: types.REQUEST_DATA_PENDING,
    data: null
  }
}

export function requestDataSuccess(data) {
  return {
    type: types.REQUEST_DATA_SUCCESS,
    data
  }
}

export function requestDataError(err) {
  return {
    type: types.REQUEST_DATA_PENDING,
    err
  }
}

