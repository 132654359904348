import React from "react";
import {connect } from "react-redux";
import PrimaryCategory from "../components/PrimaryCategory";

export default function nonTerminalCategoryComponent(domain, name) {
  var Component = class NonTerminalCategory extends React.Component {
    render() {
      return <PrimaryCategory
        domain= { domain }
        name={ name }
        subCategories = { this.props.subCategories }
      />
    }
  }
  function mapStateToProps(state) {
    let subCategories = state.data.subCategories[name];
    return { subCategories }
  }
  return connect(mapStateToProps)(Component);
}

