import React from "react";
import {connect } from "react-redux";
import Domain from "../components/Domain";

class Miscellany extends React.Component {
  render() {
    return <Domain
      name="Gifts"
      { ...this.props }
    />
  }
}

function mapStateToProps(state) {
  let { categories } = state.data;
  return {
    categories: {
      "Men's Jewelry": categories["Men's Jewelry"],
      "Sterling Items": categories["Sterling Items"],
      "Sundry": categories["Sundry"]
    }
  }
}

export default connect(mapStateToProps)(Miscellany);

