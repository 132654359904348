import React from "react";
import { History } from "react-router";

function connectHistory (Component) {
  return React.createClass({
    mixins: [ History ],
    render () {
      return <Component {...this.props} history={this.history} />
    }
  })
}

export default connectHistory;

