import React from "react";
import aboutUsImageSrc from "../public/about_us.png";
import aboutUsImage2Src from "../public/about_us_in_store.jpg";

import style from "./AboutUs.css";
export default class AboutUs extends React.Component {
  render() {
    return (
      <div className="AboutUs">
        <p>
          Firestone and Parson was founded in 1946 by Edwin I. Firestone and Kenneth B. G. Parson, Jr., at 344 Boylston Street in Boston. The firm continues to be owned and operated by the son and grandson of its founders. In 1956 the company leased premises at the Ritz-Carlton Hotel on Arlington street, where it remained for forty years. In 2021 Firestone and Parson moved to its present location at 17 Arlington Street.
        </p>
        <p>
          <img
            alt="The interior of Firestone and Parson on Newbury street"
            src={aboutUsImage2Src}
            style={{
              float: "right",
              display: "block",
              margin: "1em",
              width: 450,
            }}
          />
          Firestone and Parson is dedicated to high quality inventory and to thoughtful, understated presentation. The company's interest in quality and value has led it, over the years, to expand its purchasing and sale of old jewelry, fine diamonds, colored stones, and rare antique silver, while also maintaining a full line of classic modern pieces. Recently the firm has further developed its in-house jewelry capabilities, increasing its ability to serve clients, create original designs, and execute custom orders.
        </p>
        <p>
          Highlights in the firm’s history include record prices paid at auction for gold (a George II teapot in 1967), for Irish silver (a George II bowl engraved with a racing scene in 1973), for American silver (a Paul Revere II Coffee Pot in 2004), as well as numerous sales to major museums. Firestone and Parson has sold historic diamonds, superb colored stones, and rare signed jewels. We take great pride in the part we have played in developing significant private collections. The purchase and sale of estates and individual jewels are at the heart of the firm’s business.
        </p>
        <p>
          The firm’s knowledgeable staff provides a full range of services related to jewelry and fine antiques, including valuations, consultation, and repairs. Firestone and Parson also has significant experience handling diverse materials outside its focus areas, and has effectively advised clients concerning their decorative arts, Americana, and fine paintings.
        </p>
        <p>
          Firestone and Parson actively seeks to purchase fine jewelry and antiques, and will also act as a selling agent. Transactions are held in the strictest confidence and offers are backed with immediate payment. Firestone and Parson will endeavor to meet or improve on any prior bid or auction estimate. We recommend that sellers seek an independent valuation before contacting us.
        </p>
        <p>
          Firestone and Parson is open 10:00AM to 4:00PM Monday through Friday. It is a longtime member of the National Antique and Art Dealers Association of America.
        </p>
        <img
          alt="Earings, bracelets, necklaces and brooches."
          src={aboutUsImageSrc}
          style={{ display: "block", width: "90%", margin: "1em auto" }}
        />
        <p>
          Firestone and Parson is committed to making its website accessible to all people, including individuals with disabilities. We are in the process of making sure our website complies with best practices and standards as defined by Section 508 of the U.S. Rehabilitation Act and Level AA of the World Wide Web Consortium (W3C) Web Content Accessibility Guidelines 2.0. These guidelines explain how to make web content more accessible for people with disabilities. Conformance with these guidelines will help make the web more user friendly for all people. If you would like additional assistance or have accessibility concern, please contact us at
          <a
            href="mailto:info@firestoneandparson.com"
            style={{ color: "#005069" }}
          >
            info@firestoneandparson.com
          </a>
          or 617-266-1858.
        </p>
      </div>
    );
  }
}
