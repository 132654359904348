import React from "react";
import {connect } from "react-redux";
import Domain from "../components/Domain";

class Jewelry extends React.Component {
  render() {
    return <Domain
      name="Jewelry"
      { ...this.props }
    />
  }
}

function mapStateToProps(state) {
  let { categories } = state.data;

  let rings                 = categories["Rings"];
  let earrings              = categories["Earrings"];
  let necklaces             = categories["Necklaces"];
  let bracelets             = categories["Bracelets"];
  let broochesAndPendants   = categories["Brooches and Pendants"];
  let signed                = categories["Signed"];
  let antiqueJewelry        = categories["Antique Jewelry"];
  let artDecoJewelry        = categories["Art Deco Jewelry"];
  let lateTwentiethCentury  = categories["Late 20th Century"];

  return {
    categories: {
      "Rings": rings,
      "Earrings": earrings,
      "Necklaces": necklaces,
      "Bracelets": bracelets,
      "Brooches and Pendants": broochesAndPendants,
      "Signed" : signed,
      "Antique Jewelry": antiqueJewelry,
      "Art Deco Jewelry": artDecoJewelry,
      "Late 20th Century": lateTwentiethCentury
    }
  }
}

export default connect(mapStateToProps)(Jewelry);

