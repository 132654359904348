export default function priceToText(price) {
  var price = parseInt(price);
  if (price < 2500) {
    return "Price is less than $2500";
  } else if ( price >= 2500 && price < 5000 )  {
    return  "Price is greater than $2500 and less than $5000";
  } else if ( price >= 5000 && price < 10000 )  {
    return "Price is greater than $5000 and less than $10000";
  } else if ( price >= 10000 && price < 20000 )  {
    return "Price is greater than $10000 and less than $20000";
  } else if ( price >= 20000 && price < 50000 )  {
    return "Price is greater than $20000 and less than $50000";
  } else if ( price >= 50000)  {
    return "Price is greater than $50000";
  }
}
