import React from "react";
import { getCloudinaryImage } from "../utils/cloudinaryUtils";
import priceToText from "../utils/priceToText";
import ItemViewer from "./ItemViewer";
import ComponentGallery from "react-component-gallery";
import "./TerminalCategory.css";
import Infinite from "react-infinite";

export default class TerminalCategory extends React.Component {
  static defaultProps = {
    items: []
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.state.selectedItem = this.props.items[0];
  } selectItem(i) {
    this.setState({
      selectedItem: this.props.items[i]
    });
  }

  getItems() {
    return this.props.items.map((item, i) => {
      return (
        <div
          aria-label={item.description + " " + priceToText(item.price)}
          role="button"
          tabIndex="0"
          className="Item"
          key={i}
          onClick={this.selectItem.bind(this, i)}
        >

          {getCloudinaryImage(item.imageId, {
            height: 225,
            width: 225,
            backgroundImage: true,
            crop: "fit",
          })}
        </div>
      );
    });
  }
  // getRows() {
  //   let elements = this.getCategoryElements();
  //   let chunked = _.chunk(elements, 3);
  //   let asdf = style;
  //   return _.map(chunked, (chunk) => {
  //     return <div className={ "flexRow" }>
  //       { chunk }
  //     </div>
  //   })
  // }
  //
  getRows() {
    let items = this.getItems();
    let chunked = _.chunk(items, 2);
    return _.map(chunked, (chunk, i) => {
      return (
        <div key={i} className="ItemRow">
          {chunk}
        </div>
      );
    });
  }

  getSelectedItem() {
    return this.state.selectedItem;
  }

  renderItemViewer(item) {
    return <ItemViewer {...item} />;
  }

  render() {
    let item = this.getSelectedItem();
    return (
      <div>
        {this.renderItemViewer(item)}
        <div className="ComponentGalleryWrapper">
          <Infinite
            ref="wrapper"
            className="Incandenza"
            containerHeight={653}
            elementHeight={225}
          >
            {this.getRows()}
          </Infinite>
        </div>
      </div>
    );
  }
}
