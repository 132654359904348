export default function priceToColor(price) {
  var price = parseInt(price);
  if (price < 2500) {
    return "#C077D2";
  } else if ( price >= 2500 && price < 5000 )  {
    return  "#6A87D0";
  } else if ( price >= 5000 && price < 10000 )  {
    return "#ECDF11";
  } else if ( price >= 10000 && price < 20000 )  {
    return "#E06969";
  } else if ( price >= 20000 && price < 50000 )  {
    return "#E89F20";
  } else if ( price >= 50000)  {
    return "#89F189";
  }
}

