import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import dataReducer from '../reducers/data';

const createStoreWithMiddleware =
  applyMiddleware( thunkMiddleware)(createStore);

export default function configureStore(initialState) {
  return createStoreWithMiddleware(dataReducer, initialState);
}
