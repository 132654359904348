import React from "react";
import _cloudinary from "cloudinary-core";

let cloudinary = _cloudinary.Cloudinary.new();

cloudinary.config("cloud_name", "dhf3bjetk");
cloudinary.config("api_key", "747652643799132");

const DEFAULT_CLOUDINARY_OPTIONS = {
  height: 250,
  width: 250,
};

export function getCloudinaryImage(
  id,
  options = DEFAULT_CLOUDINARY_OPTIONS,
  alt = "",
) {
  options = {
    ...options,
    secure: true,
  };
  let url = cloudinary.url(id, options);

  window.cloudinary = cloudinary;

  console.log({ id, options });

  if (options.backgroundImage) {
    let style = {
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundImage: `url(${url})`,
    };
    return <div className="backgroundImage" style={style} />;
  } else {
    return <img src={url} alt={alt} />;
  }
}

export default cloudinary;
