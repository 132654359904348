import React from 'react';
import Router, { IndexRoute, Route  } from "react-router";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import Main from "./containers/Main";
import Jewelry from "./containers/Jewelry";
import Silver from "./containers/Silver";
import Miscellany from "./containers/Miscellany";
import createBrowswerHistory from "history/lib/createHashHistory";
import nonTerminalCategoryComponent
  from "./containers/nonTerminalCategoryComponent";
import terminalCategoryComponent from "./containers/terminalCategoryComponent";
import AboutUs from "./components/AboutUs";
import Custom from "./components/Custom";
import Services from "./components/Services";

const store = configureStore();

export class Index extends React.Component {
  render() {
    return <div className="Index">
       <main aria-label="A landing page image"></main>
    </div>;
  }
}

React.render(
  <Provider store={ store }>
    { () => <Router history= { createBrowswerHistory() }>
       <Route path="/" component={ Main }>
         <IndexRoute component={ Index }/>
         <Route path="/jewelry"
            component={ Jewelry } />
          { /* RINGS ROUTES */ }
         <Route path="/jewelry/rings"
            component={ nonTerminalCategoryComponent("Jewelry", "Rings") } />
         <Route path="/jewelry/rings/diamond%20and%20colored%20stone"
            component={
              terminalCategoryComponent("Rings", "Diamond and Colored Stone")
            }
         />
         <Route path="/jewelry/rings/antique"
            component={ terminalCategoryComponent("Rings", "Antique") }
         />
         <Route path="/jewelry/rings/fashion"
            component={ terminalCategoryComponent("Rings", "Fashion") }
         />


         { /* EARRINGS ROUTES */ }
         <Route path="/jewelry/earrings"
            component={ terminalCategoryComponent("Earrings") } />

         {/*
         <Route path="/jewelry/earrings/antique"
            component={ terminalCategoryComponent("Earrings", "Antique") }
         />
         <Route path="/jewelry/earrings/art%20deco"
            component={ terminalCategoryComponent("Earrings", "Art Deco") }
         />
         <Route path="/jewelry/earrings/late%2020th"
            component={ terminalCategoryComponent("Earrings", "Late 20th") }
         />
         */}

         { /* NECKLACES  ROUTES */ }
         <Route path="/jewelry/necklaces"
            component={ nonTerminalCategoryComponent("Jewelry", "Necklaces") } />
         <Route path="/jewelry/necklaces/antique"
            component={ terminalCategoryComponent("Necklaces", "Antique") }
         />
         <Route path="/jewelry/necklaces/fashion"
            component={ terminalCategoryComponent("Necklaces", "Fashion") }
         />

         {/*
         <Route path="/jewelry/necklaces/late%2020th"
            component={ terminalCategoryComponent("Necklaces", "Late 20th") }
         />
         */}




         { /* BRACELETS  ROUTES */ }
         <Route path="/jewelry/bracelets"
            component={ nonTerminalCategoryComponent("Jewelry", "Bracelets") } />
         <Route path="/jewelry/bracelets/antique"
            component={ terminalCategoryComponent("Bracelets", "Antique") }
         />
         <Route path="/jewelry/bracelets/art%20deco"
            component={ terminalCategoryComponent("Bracelets", "Art Deco") }
         />
         <Route path="/jewelry/bracelets/late%2020th"
            component={ terminalCategoryComponent("Bracelets", "Late 20th") }
         />


         { /* BROOCHES AND PENDANTS  ROUTES */ }

          <Route path="/jewelry/brooches%20and%20pendants"
            component={ nonTerminalCategoryComponent("Jewelry", "Brooches and Pendants") } />
         <Route path="/jewelry/brooches%20and%20pendants/antique"
            component={ terminalCategoryComponent("Brooches and Pendants", "Antique") }
         />
         <Route path="/jewelry/brooches%20and%20pendants/art%20deco"
            component={ terminalCategoryComponent("Brooches and Pendants", "Art Deco") }
         />
         <Route path="/jewelry/brooches%20and%20pendants/late%2020th"
            component={ terminalCategoryComponent("Brooches and Pendants", "Late 20th") }
         />

         { /*SIGNED*/ }
         <Route path="/jewelry/signed"
            component={ terminalCategoryComponent("Signed") }
         />
         { /*Antique Jewelry*/ }
         <Route path="/jewelry/antique%20jewelry"
            component={ terminalCategoryComponent("Antique Jewelry") }
         />
         { /*Art Deco Jewelry*/ }
         <Route path="/jewelry/art%20deco%20jewelry"
            component={ terminalCategoryComponent("Art Deco Jewelry") }
         />
         { /*Late 20th*/ }
         <Route path="/jewelry/late%2020th%20century"
            component={ terminalCategoryComponent("Late 20th Century") }
         />


         <Route path="/silver" component={ Silver } />

         <Route path="/silver/antique%20english%20silver"
           component={ terminalCategoryComponent("Antique English Silver") } />

         <Route path="/silver/early%20american%20silver"
           component={ terminalCategoryComponent("Early American Silver") } />

         <Route path="/silver/modern%20silver"
           component={ terminalCategoryComponent("Modern Silver") } />

         {/*
         <Route path="/silver/modern%20silver/early%2020th%20century"
           component={ terminalCategoryComponent("Modern Silver", "Early 20th Century") } />
         <Route path="/silver/modern%20silver/reproduction"
           component={ terminalCategoryComponent("Modern Silver", "Reproduction") } />
         <Route path="/silver/modern%20silver/gifts"
           component={ terminalCategoryComponent("Modern Silver", "Gifts") } />
           */}
         
         <Route path="/gifts" component={ Miscellany } />
         <Route path="/gifts/men%27s%20jewelry"
           component={ terminalCategoryComponent("Men's Jewelry") } />
         <Route path="/gifts/sterling%20items"
           component={ terminalCategoryComponent("Sterling Items") } />
         <Route path="/gifts/sundry"
           component={ terminalCategoryComponent("Sundry") } />

         { /*Featured*/ }
         <Route path="/featured"
            component={ terminalCategoryComponent("Featured") }
         />
         <Route path="/aboutus"
            component={ AboutUs }
         />
         <Route path="/custom"
            component={ Custom }
         />
         <Route path="/services"
            component={ Services }
         />
       </Route>
    </Router> }
  </Provider>
, document.querySelector("#myApp"));

