import React, { PropTypes } from "react";
import { Link } from "react-router";
import cloudinary, { getCloudinaryImage } from "../utils/cloudinaryUtils";
import connectHistory from "../decorators/connectHistory";

import style from "./CategoryTile.css";

const NAME_MODIFIERS = {
  "Signed": "Signed Jewelry",
  "Late 20th": "Modern",
  "Late 20th Century": "Modern Jewelry",
  "Antique" :  "Antique & Estate",
  "Art Deco": "Art Deco & Mid Century",
  "Art Deco Jewelry": "Art Deco & Mid Century",
  "Modern Silver": "20th Century & Modern",
  "Fashion": "Modern",
  "Sundry": "Jewelry",
  "Sterling Items": "Sterling Silver"
}


function modifyNameIfNecessary(name, domain) {
  if ( domain === "Rings" && name === "Fashion") {
    return name;
  }
  return NAME_MODIFIERS[name] || name;
}

class CategoryTile extends React.Component {

  getUrl() {
    if (this.props.link) return this.props.link;
    let url = ("/" + this.props.domain + "/" + this.props.category).toLowerCase();
    return window.escape(url);
  }

  render() {
    return <Link to={ this.getUrl() }>
      <div className="CategoryTile">
        { getCloudinaryImage(this.props.imageId, { crop: "fit", height: 300, width: 300 }, `${this.props.category} category` ) }
        <div className="CategoryTileOverlay">
        { modifyNameIfNecessary(this.props.category, this.props.domain) }
        </div>
      </div>
    </Link>
  }

}

export default connectHistory(CategoryTile);
