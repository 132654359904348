import React from "react";

import servicesImageSrc from "../public/services_interior_2.jpg";

import style from "./Services.css";
export default class Services extends React.Component {
  render() {
    return (
      <div className="Services">
        <div>
          <h4>Appraisal and Advisory</h4>
          <p>
            Firestone and Parson serves clients, collectors, estates, and
            institutions, providing guidance and support in the evaluation and
            stewardship of valuables with special focus on fine diamonds and
            colored stones, jewelry, antique silver and objet d’arts.
          </p>
          <p>
            We provide documents that can be used for insurance and estate
            purposes and advise in the strategic disposal of valuables.
          </p>
          <p>
            <img
	      alt="A Firestone and Parson jewelry case containing necklaces, earings, and bracelets"
              src={servicesImageSrc}
              style={{
                float: "right",
                display: "block",
                margin: "1em",
                width: 380
              }}
            />
            Firestone and Parson staff have significant experience and training
            in gemology as well as in the specialized fields of antique and
            estate jewelry, early American and antique English silver. We have
            also advised clients concerning their decorative arts and antiques,
            Americana, and fine paintings. We bring over 70 years of experience
            and trade relationships to serve the requirements of our clientele.
          </p>
          <p>
            Firestone and Parson is also available to serve in an advisory role
            to buyers and collectors on acquisitions and sales of property
            through auction or other third parties.
          </p>
        </div>
        <div>
          <h4>Purchasing </h4>
          <p>
            Firestone and Parson actively seeks to purchase fine jewelry and
            antiques. We also facilitate transactions on behalf of sellers,
            which have included sales to important collectors and museums.
            Transactions are held in the strictest confidence and offers are
            backed with immediate payment.
          </p>
          <p>
            Firestone and Parson will endeavor to meet or improve on any prior
            bid or auction estimate. We recommend that sellers seek an
            independent valuation before contacting us.
          </p>
        </div>
        <div>
          <h4>Jewelry Design and Repair</h4>
          <p>
            Firestone and Parson staff and in-house jewelers have comprehensive
            knowledge of traditional and modern jewelry manufacturing and
            design. We are passionate about making beautiful jewelry of high
            quality and high integrity in design and execution. We are
            experienced in the analysis of old jewelry with regard to alteration
            and redesign and are knowledgable about all types of jewelry repair.
          </p>
          <p>
            We work with stones from our stock, or stones supplied by customers.
            We also offer expertise in sourcing fine stones and semi-precious
            material, where we make effective use of longstanding trade
            relationships.
          </p>
        </div>
      </div>
    );
  }
}
