import React from "react";
import { getCloudinaryImage } from "../utils/cloudinaryUtils";
import priceToColor from "../utils/priceToColor";
import style from "./ItemViewer.css";

const IMAGE_OPTIONS = {
  height: 400,
  width: 400,
  crop: "fit",
};

export default class ItemViewer extends React.Component {
  renderImage() {
    return (
      <div className="ItemViewerImage">
        <img
          src={
            "https://res.cloudinary.com/dhf3bjetk/image/upload/h_800,w_800,c_fill/" + this
              .props.imageId
          }
          style={{ height: 400, width: 400 }}
        />
      </div>
    );
    // return <div className="ItemViewerImage">
    //     { getCloudinaryImage(this.props.imageId, IMAGE_OPTIONS, this.props.description  ) }
    // </div>
  }

  getDescriptionStyle() {
    let hex = priceToColor(this.props.price);
    return {
      backgroundColor: `${hex}`,
      height: "20px",
      width: "20px",
      margin: "0 auto",
    };
  }

  render() {
    return (
      <div className="ItemViewer">
        {this.renderImage()}
        <div className='ItemViewerDescription'>
          <div className="ItemViewerDescriptionContent">
            {this.props.description}
            {this.props.dimensions &&
              this.props.dimensions.length > 4 &&
              (
                <div className="ItemViewerDimensions">
                  {this.props.dimensions}
                </div>
              )}
          </div>
          <div style={this.getDescriptionStyle()} className="priceColor"></div>
        </div>
      </div>
    );
  }
}
