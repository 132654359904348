import React from "react";
import _ from "lodash";
import CategoryTile from "./CategoryTile";
import style from "./Domain.css";
class Domain  extends React.Component {

  getRows() {
    let elements = this.getCategoryElements();
    let chunked = _.chunk(elements, 3);
    let asdf = style;
    return _.map(chunked, (chunk, i) => {
      return <div key={ i } className={ "flexRow" }>
        { chunk }
      </div>
    })
  }

  getCategoryElements() {
    let { categories } = this.props;
    let tiles = []
    for (var key in categories) {
      let category = categories[key];
      tiles.push(
        <div className={ "flexElement" }>
        <CategoryTile
          key={ key }
          domain = { this.props.name }
          category={ key }
          { ...category } />
        </div>)
    }
    return tiles;
  }

  render() {
    return <div className="Domain">
      { this.getRows() }
    </div>
  }

}

export default Domain;

